import { ThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import generateStore from "./redux/store";
import theme from "./theme";
import Header from "./app/components/Header";
import Menu from "./app/components/Menu";
import Home from "./app/pages/Home";
import Loader from "./app/components/Loader";

const App = () => {
  const store = generateStore();

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Header />
          <Menu />
          <Loader />
          <Switch>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route>
              <Redirect to="/home" />
            </Route>
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
