import axios from "axios";
import environments from "./../../environments";
import { civilStatusList } from "./../utils/index";

class Repository {
  constructor() {
    this.url = (path) => `${environments.apiUrl}/leads/${path}`;
    this.origin = environments.origin;
    this.axiosConfiguration = {
      headers: {
        authorization: environments.oauthToken,
      },
    };
  }

  async createLead({ /* credit, */ ...user }) {
    console.log('user:', user);
    const lead = {
      origin: this.origin,
      user,
    };

    const observations = [
      {
        description: `Estado civil: ${
          civilStatusList.find(({ value }) => value === user.civilStatus).text
        }`,
      },
    ];

    if (user.spouseDocument)
      observations.push({
        description: `Cédula del Cónyuge: ${user.spouseDocument}`,
      });

    if (user.incomes)
      observations.push({
        description: `Ingresos Familiares: $ ${Number(
          user.incomes
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      });

    /* if (credit)
      observations.push({
        description: `Crédito Biess: $ ${Number(credit).toLocaleString(
          "en-US",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        )}`,
      }); */

      console.log(' this.url("integration"),:',  this.url("integration"),)
    const { data } = await axios.post(
      this.url("integration"),
      { ...lead, observations },
      this.axiosConfiguration
    );

    return data;
  }

  async createTransactioId({ ...user },id) {
    const {  projectName } = environments.biometricValidationUrl;
    const callbackUrl = `https://${window.location.hostname}/home`;
  console.log('user:', user)
    const lead = {
      ...user ,
      leadId:id,
      callback:callbackUrl
    };
   
      console.log(' this.url("createTransactionIdQualificar"),:',  this.url("integration"),)
    const { data } = await axios.post(
      this.url("createTransactionIdQualificar"),
      { ...lead },
      this.axiosConfiguration
    );

    console.log('data transaction:', data)
    return data;
  }

  async validateBiometricId(biometricId, leadId) {
    console.log('validateBiometricId function call to ws:');
    const { data } = await axios.post(
      this.url("validateBiometricId"),
      { biometricId, leadId },
      this.axiosConfiguration
    );

    return data;
  }

  async validateBiometricQualificar(biometricId, leadId, idTransaction) {


    console.log('validateBiometricId function call to ws:');
    const { data } = await axios.post(
      this.url("validateBiometricQualificar"),
      { biometricId, leadId, transactionId:idTransaction },
      this.axiosConfiguration
    );

    return data;
  }

  async getTyCQualificar() {
    const { data } = await axios.post(
      this.url("tycQualificar"),
      {},
      this.axiosConfiguration
    );
    return data;
  }
}

export default new Repository();
