import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory, useLocation } from "react-router-dom";
import {
  isEmail,
  verifyIdentification,
  delay,
  splitFullName,
  civilStatusList,
} from "./../utils";
import CustomerForm from "./../components/CustomerForm";
import environments from "./../../environments";
import { useDispatch, useSelector } from "react-redux";
import {
  createLead,
  validateBiometricId,
  closeModal,
  resetAmount,
} from "./../../redux/duck";
import AmountTable from "./../components/AmountTable";
import { Typography } from "@material-ui/core";
import ModalTyC from "../components/ModalTyC";

const useQuery = () => new URLSearchParams(useLocation().search);

const Home = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const history = useHistory();
  const { openValidation, modal, montosPreaprobados,transactionId  } = useSelector(
    (state) => state.data
  );

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [document, setDocument] = useState("");
  const [civilStatus, setCivilStatus] = useState(civilStatusList[0].value);
  const [spouseDocument, setSpouseDocument] = useState("");
  const [incomes, setIncomes] = useState("");
  const [tycCheck, setTycCheck] = useState(false);
  /* const [hasCredit, setHasCredit] = useState(false);
  const [credit, setCredit] = useState(""); */

  useEffect(() => {
    const timer = setInterval(() => {
      const expiresAt = localStorage.getItem("expiresAt");
      if (expiresAt && new Date() > new Date(expiresAt)) localStorage.clear();
    }, 1000);
    return () => clearInterval(timer);
  });

  const isDisabled = () => {
    let base =
      fullName &&
      phone &&
      email &&
      isEmail(email) &&
      document &&
      verifyIdentification(document, "CARD") &&
      tycCheck &&
      incomes;

    if (["free_union", "married"].includes(civilStatus))
      base = base && verifyIdentification(spouseDocument, "CARD");

    /* if (hasCredit) base = base && Number(credit) > 0; */

    return !base;
  };

  const handleContinue = () => {
    const [name, lastname] = splitFullName(fullName);
    const data = {
      name,
      lastname,
      phone,
      email,
      document,
      civilStatus: civilStatusList.find(({ value }) => value === civilStatus)
        .value,
      avalData: true,
    };

    if (["free_union", "married"].includes(civilStatus)) {
      data.spouseDocument = spouseDocument;
    }

    if (incomes) data.incomes = incomes;

    /* if (Number(credit) > 0) data.credit = credit; */

    dispatch(createLead(data));
  };

  const props = {
    fullName,
    setFullName,
    phone,
    setPhone,
    email,
    setEmail,
    document,
    setDocument,
    civilStatus,
    setCivilStatus,
    spouseDocument,
    setSpouseDocument,
    incomes,
    setIncomes,
    isDisabled,
    handleContinue,
    civilStatusList,
    tycCheck,
    setTycCheck,
    setOpenModal
    /* hasCredit,
    setHasCredit,
    credit,
    setCredit, */
  };

  useEffect(() => {
    const { user } = { ...JSON.parse(localStorage.getItem("lead")) };

    const storedFullname = `${user?.name || ""} ${user?.lastname || ""}`.trim();

    setFullName(storedFullname);
    setPhone(user?.phone || "");
    setEmail(user?.email || "");
    setDocument(user?.document || "");
    setCivilStatus(
      (
        user?.civilStatus &&
        civilStatusList.find((item) => item.value === user.civilStatus)
      )?.value || civilStatusList[0].value
    );
    setSpouseDocument(user?.spouseDocument || "");
    setIncomes(user?.incomes || "");
    /* setHasCredit(Number(user?.credit) > 0 || false);
    setCredit(user?.credit || ""); */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const biometricId = query.get("id");
    console.log('biometricId:', biometricId);
    const lead = JSON.parse(localStorage.getItem("lead"))?.id;
    console.log('lead:', lead);

    if (biometricId && lead) {
      dispatch(validateBiometricId(biometricId, lead));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToBiometricValidation = async () => {
    await delay(6000);
    if( transactionId ){
      window.open(transactionId, "_self");
    }

    // const { domain, projectName, key } = environments.biometricValidationUrl;
    // console.log('projectName:', projectName)
    // const callbackUrl = `https://${window.location.hostname}`;
    // console.log('callbackUrl:', callbackUrl);
    // const url = `${domain}?callback=${callbackUrl}/home&key=${key}&projectName=${projectName}&idTransaction=${'sd'}`;
    // console.log('url:', url);

  //  window.open(url, "_self");
  };

  useEffect(() => {
    if (openValidation) goToBiometricValidation();
  }, [openValidation]);

  useEffect(() => {
    if (modal) {
      const { title, text, icon } = modal;

      MySwal.fire({
        title,
        html: (
          <>
            <Typography variant="subtitle1">{text}</Typography>
            {icon === "success" && montosPreaprobados.length > 0 && (
              <AmountTable montosPreaprobados={montosPreaprobados} />
            )}
          </>
        ),
        icon,
        confirmButtonText: icon === "success" ? "Finalizar" : "Entendido",
        confirmButtonColor: "#006064",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(() => {
        if (icon === "success") {
          dispatch(resetAmount());
          history.push("/");
        }
      });

      dispatch(closeModal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return <div>
    {openModal &&  <ModalTyC openModal={openModal}
     closeModal = {setOpenModal} /> }
  
    <CustomerForm {...props} />
    </div>;
};

export default Home;
