import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setMenuOpen } from "./../../redux/duck";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import CloseIcon from "@material-ui/icons/Close";

const useStyle = makeStyles((theme) => ({
  root: {
    "& > .MuiDrawer-paper": {
      backgroundColor: theme.palette.primary.main,
    },
    "& *": {
      color: "white",
    },
  },
  listItem: {
    width: 250,
  },
}));

const Menu = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const { menuOpen } = useSelector((state) => state.data);

  const options = [
    {
      text: "Inicio",
      link: "/",
    },
  ];

  const toggleDrawer = (link) => {
    history.push(link);
    dispatch(setMenuOpen(false));
  };

  return (
    <Drawer
      className={classes.root}
      anchor="left"
      open={menuOpen}
      onClose={() => dispatch(setMenuOpen(false))}
    >
      <List>
        <ListItem>
          <ListItemIcon>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => dispatch(setMenuOpen(false))}
            >
              <CloseIcon fontSize="large" color="secondary" />
            </IconButton>
          </ListItemIcon>
        </ListItem>
      </List>
      <List>
        {options.map((option, index) => (
          <ListItem
            className={classes.listItem}
            button
            key={`option_${index}`}
            onClick={() => toggleDrawer(option.link)}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={option.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Menu;
