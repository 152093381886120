import React from "react";
import {
  Dialog,
  makeStyles,
  Slide,
  Box,
  CircularProgress,
  Typography,
  Paper,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import permitirImg from "../../assets/permitir.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyle = makeStyles((theme) => ({
  root: {
    zIndex: "1099 !important",
    top: 0,

    "& .MuiBackdrop-root": {
      top: 0,
      backgroundColor: "#ffffffbb",
    },
    "& .MuiDialog-paper": {
      boxShadow: "none",
      backgroundColor: "transparent",
    },
  },
  paper: {
      padding: 48
  },
  permitirImg:{
    width:300,
    
  },
  permitirImgContainer:{
    textAlign:'center'
    
  }
}));

const Loader = () => {
  const classes = useStyle();
  const { loaderOpen, openValidation } = useSelector((state) => state.data);

  return (
    <Dialog
      key="loader"
      id="loader"
      className={classes.root}
      fullScreen
       open={loaderOpen}
      TransitionComponent={Transition}
    >
      <Box
        width={1}
        height={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {openValidation ? (
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h4">

              Redireccionando a la validación biométrica ...
            </Typography>
            <br />
            <div>
            <Typography variant="h6">

            En la siguiente pantalla,  deberás dar permiso a tu explorador para habilitar la cámara y podamos realizar el proceso biométrico
</Typography>
<div className={classes.permitirImgContainer}>  <img
              className={classes.permitirImg}
              src={permitirImg}
              alt="Permitir camara"
              border="0"
            /> </div>
<Typography variant="h6">
Adicionalmente,  recuerda tener a la mano tu cédula de identidad para finalizar la validación biométrica
</Typography>
            </div>
            
          </Paper>
        ) : (
          <CircularProgress color="primary" />
        )}
      </Box>
    </Dialog>
  );
};

export default Loader;
