import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles({
  title: {
    textAlign: "center",
    padding: 20,
  },
});

const AmountTable = ({ montosPreaprobados }) => {
  const classes = useStyle();

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.title}>
          Montos Preaprobados
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Plazo</TableCell>
              <TableCell align="center">Monto</TableCell>
              <TableCell align="center">Cuota</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {montosPreaprobados.map((row, index) => (
              <TableRow key={`monto_${index}`}>
                <TableCell align="center">{`${row.plazo / 12} años`}</TableCell>
                <TableCell align="center">
                  {`$ ${row.montoAprobado.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}
                </TableCell>
                <TableCell align="center">
                  {`$ ${row.cuota.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default AmountTable;
