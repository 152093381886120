import MenuIcon from "@material-ui/icons/Menu";
import { Button, Link, AppBar, Toolbar, IconButton } from "@material-ui/core";
import logo from "../../assets/logo.png";
import { useDispatch } from "react-redux";
import { setMenuOpen } from "./../../redux/duck";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& header": {
      backgroundColor: "white",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  separator: {
    flexGrow: 1,
  },
  logo: {
    height: theme.mixins.toolbar.minHeight,
  },
  toolbar: {
    boxShadow: theme.shadows[2]
  }
}));

export default function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar  className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => dispatch(setMenuOpen(true))}
          >
            <MenuIcon fontSize="large" color="secondary" />
          </IconButton>
          <span className={classes.separator}></span>
          <Button component={Link} to={"/"} color="inherit">
            <img
              className={classes.logo}
              src={logo}
              alt="logo trivo"
              border="0"
            />
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
