import Repository from "../app/repository";
import { addMinutes } from "../app/utils";

const initialData = {
  openValidation: false,
  menuOpen: false,
  loaderOpen: false,
  modal: null,
  transactionId:null,
  montosPreaprobados: [],
  tyc:null
};

const MENU_OPEN = "MENU_OPEN";
const SET_LOADER = "SET_LOADER";
const SET_TRANSACTION_ID = "SET_TRANSACTION_ID";
const CREATE_LEAD_SUCCESS = "CREATE_LEAD_SUCCESS";
const CREATE_LEAD_ERROR = "CREATE_LEAD_ERROR";
const VALIDATE_BIOMETRIC_ID_SUCCESS = "VALIDATE_BIOMETRIC_ID_SUCCESS";
const GET_TYC_SUCCESS = "GET_TYC_SUCCESS";
const GET_TYC_FAILED = "GET_TYC_FAILED";
const VALIDATE_BIOMETRIC_ID_ERROR = "VALIDATE_BIOMETRIC_ID_ERROR";
const CLOSE_MODAL = "CLOSE_MODAL";
const RESET_AMOUNT = "RESET_AMOUNT";

export default function reducer(state = initialData, action) {
  switch (action.type) {
    case MENU_OPEN:
      return { ...state, menuOpen: action.payload };
      case SET_TRANSACTION_ID:
      return { ...state, ...action.payload };
    case SET_LOADER:
      return { ...state, loaderOpen: true };
    case CREATE_LEAD_SUCCESS:
      return { ...state, openValidation: true };
    case CREATE_LEAD_ERROR:
      return {
        ...state,
        loaderOpen: false,
        modal: {
          icon: "error",
          text:
            "Ha habido un error al procesar la solicitud. Por favor, inténtelo nuevamente",
          title: "ERROR",
        },
      };
    case VALIDATE_BIOMETRIC_ID_SUCCESS:
      return {
        ...state,
        loaderOpen: false,
        modal: {
          icon: "success",
          text: action.payload.message,
        },
        montosPreaprobados: action.payload.data || [],
      };
    case GET_TYC_SUCCESS:
      return {
        ...state,
        tyc:action.payload.tyc
      };
    case VALIDATE_BIOMETRIC_ID_ERROR:
      return {
        ...state,
        loaderOpen: false,
        modal: {
          icon: "error",
          text:
            "Ha habido un error en la validación biométrica. Por favor, inténtelo nuevamente",
          title: "ERROR",
        },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modal: null,
      };
    case RESET_AMOUNT:
      return {
        ...state,
        montosPreaprobados: [],
      };
    default:
      return state;
  }
}

export const setMenuOpen = (status) => (dispatch) =>
  dispatch({
    type: MENU_OPEN,
    payload: status,
  });

export const createLead = (user) => async (dispatch) => {
  dispatch({ type: SET_LOADER });

  try {
    let isNewUser = false;
    const storedUser = JSON.parse(localStorage.getItem("lead"))?.user || {};
    console.log('storedUser:', storedUser);
    console.log('user:', user)
    for (let key in user) {
      if (user[key] !== storedUser[key]) {
        isNewUser = true;
        break;
      }
    }

    if (isNewUser) {
      const { id } = await Repository.createLead(user);
      localStorage.setItem("lead", JSON.stringify({ id, user }));
      localStorage.setItem("expiresAt", addMinutes(new Date(), 10).toISOString());
    }
    const storedUserBefore = JSON.parse(localStorage.getItem("lead")) || {};
    console.log('storedUserBefore:', storedUserBefore)
   const responseData = await Repository.createTransactioId(user,storedUserBefore.id);
   localStorage.setItem("idTransaction",responseData.idTransaction);
   console.log('responseData:', responseData)
   dispatch({
    type: SET_TRANSACTION_ID,
    payload: {
      transactionId:responseData.urlTransaction
    },
  });

   console.log('responseData:', responseData)

    dispatch({ type: CREATE_LEAD_SUCCESS });
  } catch (error) {
    console.error(error);
    dispatch({ type: CREATE_LEAD_ERROR });
  }
};

export const validateBiometricId = (biometricId, lead) => async (dispatch) => {
  console.log('validateBiometricId: funcion de validacion');
  dispatch({ type: SET_LOADER });

  try {
    const idTransaction = localStorage.getItem("idTransaction");
    const {
      montosPreaprobados,
      message,
    } = await Repository.validateBiometricQualificar(biometricId, lead, idTransaction);
    localStorage.removeItem("lead");
    localStorage.removeItem("idTransaction");
    console.log('montosPreaprobados:', montosPreaprobados)

    const data = montosPreaprobados.filter(({ plazo }) =>
      [10, 15, 20].includes(plazo / 12)
    );

    dispatch({
      type: VALIDATE_BIOMETRIC_ID_SUCCESS,
      payload: {
        data: message.isApproved && data,
        message:
          (message.text && message.text.length > 0 && message.text) ||
          "Validación realizada exitosamente. En poco tiempo se contactará con usted un asesor de nuestra empresa",
      },
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: VALIDATE_BIOMETRIC_ID_ERROR,
    });
  }
};

export const getTyCQualificar = () => async (dispatch) => {
  try {
    const data = await Repository.getTyCQualificar();

    dispatch({
      type: GET_TYC_SUCCESS,
      payload: {
        tyc: data
      },
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_TYC_FAILED,
    });
  }
};

export const closeModal = () => async (dispatch) =>
  dispatch({ type: CLOSE_MODAL });

export const resetAmount = () => async (dispatch) =>
  dispatch({ type: RESET_AMOUNT });