const environments = {
  apiUrl: process.env.REACT_APP_API_URL,
  oauthToken: process.env.REACT_APP_OAUTH_TOKEN,
  origin: process.env.REACT_APP_ORIGIN,

  biometricValidationUrl: {
    domain: process.env.REACT_APP_VALIDATION_DOMAIN,
    projectName: process.env.REACT_APP_VALIDATION_PROJECT_NAME,
    key: process.env.REACT_APP_VALIDATION_KEY,
  },

  urlTyC: process.env.REACT_APP_URL_TYC
};

export default environments;
