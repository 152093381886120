import { parseFullName } from "parse-full-name";
const isValidCard = (card) => card.length === 10;
const isValidRuc = (ruc) => ruc.length === 13 && ruc.substring(10) !== "000";
const isValidPublicRuc = (digit) => digit.slice(-4).toString() !== "0,0,0,0";

const calculate = (coefficients, digits, module, isCard = false) => {
  const verifyingDigit = digits.pop();
  const process = coefficients
    .map((value, index) => {
      const multiplication = value * digits[index];
      if (isCard) {
        return multiplication < 10 ? multiplication : multiplication - 9;
      }
      return multiplication;
    })
    .reduce((a, b) => a + b);
  if (process % module === 0) return verifyingDigit === 0;
  let calculatedNumber = module - (process % module);
  if (isCard && calculatedNumber === module) calculatedNumber = 0;
  return calculatedNumber === verifyingDigit;
};

const verifyCard = (digits) => {
  const coefficients = [2, 1, 2, 1, 2, 1, 2, 1, 2];
  const module = 10;
  return calculate(coefficients, digits, module, true);
};

const publicRuc = (digits) => {
  const coefficients = [3, 2, 7, 6, 5, 4, 3, 2];
  const module = 11;
  return calculate(coefficients, digits, module);
};

const legalAndForeignersRuc = (digits) => {
  const coefficients = [4, 3, 2, 7, 6, 5, 4, 3, 2];
  const module = 11;
  return calculate(coefficients, digits, module);
};

const verifyRuc = (digits) => {
  // R.U.C. Natural
  if (digits[2] >= 0 && digits[2] < 6) return verifyCard(digits.slice(0, 10));

  // R.U.C. Públicos
  if (digits[2] === 6 && isValidPublicRuc(digits)) {
    return publicRuc(digits.slice(0, 9));
  }

  // R.U.C. Jurídicos y extranjeros sin cédula:
  if (digits[2] === 9) return legalAndForeignersRuc(digits.slice(0, 10));
  return false;
};

export const verifyIdentification = (identification, type) => {
  if (typeof identification === "string" && /^\d+$/.test(identification)) {
    const digits = identification.split("").map(Number);
    const provincialCode = digits[0] * 10 + digits[1];
    if (
      provincialCode >= 1 &&
      (provincialCode <= 24 || provincialCode === 30)
    ) {
      if (type === "CARD" && isValidCard(identification)) {
        return verifyCard(digits);
      }
      if (type === "RUC" && isValidRuc(identification)) {
        return verifyRuc(digits);
      }
    }
  }
  return !(type === "CARD" || type === "RUC");
};

export const isEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const splitFullName = (fullName) => {
  const { first, middle, last } = parseFullName(fullName);
  const middleSplitted = middle.split(" ").filter((text) => text);
  const middleLast = middleSplitted.pop() || "";

  let name = `${first} ${middleSplitted.join(" ")}`;
  let lastName = `${middleLast} ${last}`.trim();

  if (!name.trim()) [name, lastName] = [lastName, ""];

  return [name, lastName];
};

export const civilStatusList = [
  { text: "Soltero", value: "single" },
  { text: "Unión libre", value: "free_union" },
  { text: "Casado", value: "married" },
  { text: "Separado", value: "separated" },
  { text: "Viudo", value: "widower" },
  { text: "Divorciado", value: "divorced" },
];

export const addMinutes = (dt, minutes) =>
  new Date(dt.getTime() + minutes * 60000);
