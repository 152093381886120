import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Link,
  Checkbox,
  Divider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import background from "../../assets/creditos.jpg";
import qualificar from "../../assets/logo-by-qualificar.png";
import { isEmail, verifyIdentification } from "./../utils";
import CallbackButton from "./../components/CallbackButton";
import environments from "./../../environments/index";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight + 12}px)`,
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "24px 0",
    overflow: "auto",
  },
  formContainer: {
    backgroundColor: "white",
    marginLeft: "5%",
    borderRadius: 12,
    width: "100%",
    padding: "32px 22px",
    boxShadow: theme.shadows[10],
    "& .MuiInputBase-input": {
      height: "1em",
    },
  },
  textField: {
    margin: "16px 0",
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
  TyC: {
    color: "grey",
    paddingBottom: 18,
  },
  tycButton:{
    "border": "none",
    "background": "none",
    "color": "blue",
    "cursor": "pointer"
  },
  qualificarLogo: {
    textAlign: "center",
  },
}));

const CustomerForm = ({
  fullName,
  setFullName,
  phone,
  setPhone,
  email,
  setEmail,
  document,
  setDocument,
  civilStatus,
  setCivilStatus,
  spouseDocument,
  setSpouseDocument,
  incomes,
  setIncomes,
  isDisabled,
  handleContinue,
  civilStatusList,
  tycCheck,
  setTycCheck,
  setOpenModal
  /* hasCredit,
  setHasCredit,
  credit,
  setCredit, */
}) => {
  const classes = useStyle();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={10} md={6} lg={4} className={classes.formContainer}>
        <Box display="flex" height="100%" alignItems="center">
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="primary"
                className={classes.title}
              >
                Ingrese sus datos
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                label="Nombre Completo"
                value={fullName}
                onChange={({ target: { value } }) => setFullName(value)}
                variant="outlined"
                autoComplete="name"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                label="Teléfono"
                value={phone}
                onChange={({ target: { value } }) => setPhone(value)}
                variant="outlined"
                autoComplete="tel"
                onInput={(e) => {
                  e.target.value = e.target.value
                    .slice(0, 10)
                    .replace(/[^0-9]/g, "");
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                placeholder="ejemplo@mail.com"
                label="Email"
                type="email"
                value={email}
                onChange={({ target: { value } }) => setEmail(value)}
                variant="outlined"
                autoComplete="email"
                fullWidth
                error={email !== "" && !isEmail(email)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                label="Cédula"
                value={document}
                onChange={({ target: { value } }) => setDocument(value)}
                variant="outlined"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                fullWidth
                error={
                  document !== "" && !verifyIdentification(document, "CARD")
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Box height="85px" display="flex" alignItems="center">
                <Select
                  value={civilStatus}
                  onChange={({ target: { value } }) => setCivilStatus(value)}
                  fullWidth
                  input={
                    <OutlinedInput
                      className={classes.selectInputForm}
                      labelWidth={0}
                      name="nationality"
                    />
                  }
                >
                  {civilStatusList.map(({ text, value }) => (
                    <MenuItem key={value} value={value}>
                      {text}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Grid>

            {["free_union", "married"].includes(civilStatus) && (
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  label="Cédula del Cónyuge (opcional)"
                  value={spouseDocument}
                  onChange={({ target: { value } }) => setSpouseDocument(value)}
                  variant="outlined"
                  fullWidth
                  error={
                    spouseDocument !== "" &&
                    !verifyIdentification(spouseDocument, "CARD")
                  }
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                label="Ingreso Familiar Mensual"
                value={incomes}
                onChange={({ target: { value } }) => setIncomes(value)}
                onInput={(e) => {
                  e.target.value = e.target.value
                    .slice(0, 5)
                    .replace(/[^0-9]/g, "");
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>

            {/* <Grid item xs={12}>
              <Typography variant="subtitle1">
                <Checkbox
                  checked={hasCredit}
                  onChange={(e) => {
                    setHasCredit(e.target.checked);
                    !e.target.checked && setCredit("");
                  }}
                />
                ¿Mantiene crédito con el BIESS?
              </Typography>
            </Grid>

            {hasCredit && (
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  type="number"
                  label="Monto del crédito"
                  value={credit}
                  onChange={({ target: { value } }) => setCredit(value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            )} */}

            <Grid item xs={12} style={{ padding: "32px 0" }}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <div className={classes.qualificarLogo}>
                <img width={250} src={qualificar} alt="qualifica" />
              </div>
              <Typography variant="subtitle1" className={classes.TyC}>
                <Checkbox
                  checked={tycCheck}
                  onChange={(e) => setTycCheck(e.target.checked)}
                />{" "}
                He leído y acepto los{" "}
                <button
                className={classes.tycButton}
                onClick={()=>setOpenModal(true)}
                  // href={environments.urlTyC}
                  target="_blank"
                  variant="subtitle1"
                  color="secondary"
                  rel="noreferrer"
                >
                  Términos y Condiciones
                </button>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <CallbackButton
                isDisabled={isDisabled()}
                handleContinue={handleContinue}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={1} md={3} lg={6}></Grid>
    </Grid>
  );
};

CustomerForm.propTypes = {
  fullName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  document: PropTypes.string.isRequired,
  civilStatus: PropTypes.string.isRequired,
  spouseDocument: PropTypes.string.isRequired,
  incomes: PropTypes.string.isRequired,
  isDisabled: PropTypes.func.isRequired,
  setFullName: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setDocument: PropTypes.func.isRequired,
  setCivilStatus: PropTypes.func.isRequired,
  setSpouseDocument: PropTypes.func.isRequired,
  setIncomes: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  civilStatusList: PropTypes.array.isRequired,
  tycCheck: PropTypes.bool.isRequired,
  setTycCheck: PropTypes.func.isRequired,
  /* hasCredit: PropTypes.bool.isRequired,
  setHasCredit: PropTypes.func.isRequired,
  credit: PropTypes.bool.isRequired,
  setCredit: PropTypes.func.isRequired, */
};

export default CustomerForm;
