import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { getTyCQualificar } from '../../redux/duck';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const  ModalTyC = (props) => {

  const dispatch = useDispatch();

  const { tyc } = useSelector(
    (state) => state.data
    );
    
    console.log('tyc:', tyc)
  useEffect(() => {
    dispatch(getTyCQualificar());
    
  }, []);

  const [open, setOpen] = React.useState(false);
  const {openModal, closeModal} = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    closeModal(false);
  };


  return (
    <div>
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Términos y Condiciones"}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{textAlign:'justify'}} id="alert-dialog-slide-description">
        { tyc && tyc.terminoCondicion ? tyc.terminoCondicion : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModalTyC;