import { Button, Box } from "@material-ui/core";
import PropTypes from "prop-types";

const CallbackButton = ({ isDisabled, handleContinue }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      onClick={() => !isDisabled && handleContinue()}
    >
      <Button variant="contained" color="primary" disabled={isDisabled}>
        Continuar
      </Button>
    </Box>
  );
};

CallbackButton.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  handleContinue: PropTypes.func.isRequired,
};

export default CallbackButton;
